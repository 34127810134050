.ExpBox {
  width: 50%;
  text-align: left;
  font-size: 12px;
}

@media (max-width: 600px) {
  .ExpBox {
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .ExpBox2 {
    width: 60%;
  }
}

.ExpTitle {
  font-size: 1.75em;
  color: black;
  font-weight: 700;
  margin-top: 15px;
}

.ExpSubTitle {
  color: var(--black);
  font-size: .75em;
  font-weight: 600;
}

.ExpLine {
  margin: 0px 10px 10px 0px;
  width: 75%;
  background-color: white;
  height: 3px;
  border-radius: 50px;
  display: flex;
}

.ExpLineShort {
  width: 35%;
  margin: 10px 0px 10px 0px;

}

.ExpTime {
  font-size: 1.5em;
  color: black;
  font-weight: 700;
}

.ExpDescription {
  color: white;
  font-size: 1.25em;
  font-weight: 600;
}