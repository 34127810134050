.FullStoryPage {
  background-color: var(--olive);
  width: 100vw;
  height: 100vh;
}

.story__grid {
  margin: 0px auto;
  width: 80%;
  gap: 0px 10em;
  height: 85%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8% 10% 8% 10% 12% 10% 8% 10% 12% 10%;
}

.story__grid > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: var(--creamy);
}

.carat__holder {
  justify-content: center;
  position: relative;
  z-index: 6;
}



.span__6 {
  align-items: center;
  justify-content: center;
  grid-row: span 10;
}

.right__panel {
  grid-row: span 10;
  align-self: center;
  font-size: clamp(.75em, 100vw, 1.5em);
  color: white;
  text-align: left;
}

.span__6 > div {
  height: 50%;
  font-size: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.which__container {
  width: 100%;
  color: var(--creamy);
  font-size: .65em;
}

.quote {
  flex-direction: column;
}

.quote > h1 {
  font-size: 1.25em;
  color: var(--creamy);
  width: 100%;
  font-weight: 700;
}

.quote > p {
  margin-top: 15px;
  font-size: .5em;
  width: 80%;

}

.span__6 > div > div {
  background-image: url("../Assets/profilepic.jpeg");
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.span__2 {
  grid-row: span 4;
}

.story__grid--title {
  font-size: 3em;
  color: var(--creamy);
}

.caption__timeline {
  font-size: .8em;
  font-weight: 500;
  color: var(--creamy);
  padding: 5px 0px;
}

.story__grid--text {
  font-size: 1em;
}

.downCarat {
  position: relative;
  z-index: 3;
  width: 50px;
  fill: var(--lightOrange);
  transition: 200ms;
  height: 3.25em;
}
.quickCarat {
  position: relative;
  z-index: 3;
  width: 35px;
  fill: var(--lightOrange);
  height: auto;
  margin-left: 15px;
}

/* .downCarat:hover {
  transform: rotate(-90deg);
} */

.stroke1 {
  stroke: var(--lime)
}

.stroke2 {
  stroke: var(--darkGreen);
}


.stroke2:hover {
  stroke: var(--lime);
}

svg:hover .stroke2 {
  stroke: var(--lime);
}


.timeline__button {
  background-color: var(--forest);
  border: solid var(--kelly) 3px;
  width: 100%;
  height: 2em;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 6px;
}

.timeline__button--front {
  background-color: var(--kelly);
  border: solid var(--lime) 3px;
  position: absolute;
  width: calc(100% + 6px);
  bottom: 6px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.hint {
  position: absolute;
  background-color: var(--orange);
  padding: 5px 10px;
  border-radius:15px;
  border: solid var(--lightOrange) 3px;
  bottom: 0;
  right: 0;
}

@keyframes pop {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1);
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

/* .timeline__button:hover {
  border: solid var(--lime) 3px;
} */