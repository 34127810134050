.App {
  text-align: center;
  background-color: var(--kelly);
}

.Home {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--kelly);
}

.SplashPage {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
}

.MobileHome {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

@media (max-width: 600px) {
  .SplashPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.ScrollDownButton {
  position: absolute;
  bottom: 50px;
  right: 625px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.75em;
  width: 250px;
  font-weight: 900;
  color: white;
  pointer-events: none;
  z-index: 105;
}

.CaratAnimation {
  width: 25%;
  animation: drop 1s infinite;
}

@keyframes drop {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(50px);
  }
}

.SideBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 20%;
  position: relative;
  z-index: 100;
}

.LeftContent {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-left: 20%;
}

.RightContent {
  display: flex;
  width: 50%;
  margin-left: 50%;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 100;
}

.Headline {
  width: 60%;
}

.Work {
  height: 100%;
  background-color: var(--black);
}

.WorkIcon {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  pointer-events: none;

}

/* .TheBlur {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #0003;
  backdrop-filter: blur(10px);
  border-radius: 25px;
} */

.TheMini {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 25%;
  left: 25%;
  border-radius: 25px;
  backdrop-filter: blur(10px);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, .5);
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LinkTouchArea {
  position: relative;
  z-index: 200;
}

.miniImage {
  margin: 0px;
}

.PortfolioHeadline {
  height: 10vh;
  width: 100%;
  display: flex;
  padding-left: 20%;
}

.PortfolioArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10%;
  /* margin-right: 10%;
  background-color: #FFF2;
  border-radius: 50px;
  padding: 50px; */
}

.PortfolioTitle {
  height: 100%;
}

.TitleLineDivider {
  width: 75%;
  height: 3px;
  background-color: white;
  margin: 15px 0px;
}

.UXArea {
  display: flex;
  margin: 0px 50px;
}

.CodeArea {
  display: flex;
  margin: 15px;
  width: 20%;
  text-align: left;
  background-color: var(--kelly);
  padding: 20px;
  border-radius: 20px;
  border: solid #fff5 2px;
  box-shadow: 0px 0px 10px black;

}

@media (max-width: 600px) {
  .CodeArea {
    width: 42%;
    margin: 5px 5px;
    border: solid var(--darkGreen) 3px;
    font-size: 10px;
    border: solid #fff2 2px;
    box-shadow: 0px 0px 5px black;
  }
}

.WorkTitle {
  text-align: right;
  color: white;
  margin: 10px 0px 25px 0px;
}

.CodeTitle {
  color: black;
  font-weight: 600;
}

.CodeTools {
  color: #9EFF2E;
}

.AreaHolder {
  display: flex;
  flex-wrap: wrap;
}

.AreaTitle {
  color: white;
  font-weight: 600;
}

.ThumbnailHolder {
  height: 200px;
  width: 200px;
  border-radius: 25px;
  position: relative;
  margin: 0px 0px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, .5);
}


.LogoHolder {
  display: flex;
  margin: 10px 10px;
  width: 20%;
  height: 300px;
  /* box-shadow: 0px 10px 30px rgba(0, 0, 0, .5); */
}

.LLL {
  padding: 25px;
  border-radius: 25px;
}

.ContactPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

@media (max-width: 600px) {

  .ContactPage {
    border-radius: 20px;
  }

  .UXArea {
    margin: 0px 15px 15px 15px;
    position: relative;
    z-index: 3;
  }

  .AreaHolder {
    width: 100%;
    margin: 25px 0px;
  }

  .PortfolioArea {
    margin: 0px 0px 0px 15px;
    overflow: hidden;
  }

  .WorkTitle {
    font-size: .75em;
    text-align: center;
  }

  .ThumbnailHolder {
    width: 130px;
    height: 130px;
    margin: 0px 0px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .5);
  }

  .LogoHolder {
    width: 33%;
    height: 125px;
    margin: 0;
    padding: 0;
    box-shadow: none;
  }

  .LLL {
    border-radius: 0;
    padding: 25px;
  }

  .cream {
    background-color: var(--black);
    margin: 0;
  }
  
}

.SocialSideBar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 100;
}

.socials__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
  font-size: 1.25em;
  position: relative;
  z-index: 102;
}

.nav__social {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: .75em;

}

.MMM {
  width: 50%;
}

.NegativeIndex {
  z-index: 98;
}

.LeavesHolder {
  width: 50%;
  height: 100vh;
  position: relative;
}



.leaf {
  width: 230px;
  height: 230px;
  position: absolute;
  top: 0;
  /* left: 0; */
  right: 0;
  bottom: 0;
  z-index: 200;
  /* pointer-events: none; */
}

.leafimg {
  position: absolute;
  top: 0;
  bottom: 0;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transform-origin: center;
  animation-name: flow;
  transform: translateX(70px);
}

.leafscroll {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transform-origin: center;
  animation-name: flow;
}

.LeavesAbso {
  position: absolute;
  left: 350px;
}

@media (max-width: 600px) {

  .LeavesHolder {
    opacity: .25;
    width: 50%;
    top: 300px;
    right: -100px;
    z-index: 0;
  }

  .leaf {
    width: 225px;
    height: 225px;
  }
}




@keyframes flow {
  0% {
    transform: translateX(70px);
  }
  
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(70px);
  }
}

.inputBttn {
  width: 100%;
}

.customBttn {
  border-radius: 5px;
}

.customBttn__green {
  border-radius: 5px;
}

.FooterNav {
  width: 100%;
  display: flex;
  height: 100%;
  background-color: var(--orange);
}

.FooterNavSection {
  margin: 0px 0px 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  flex-grow: 1;
}


.FooterTitle {
  font-weight: 700;
  margin: 20px;
  color: black;
}

.FooterLink {
  color: white;
  font-size: 1.25em;
  margin: 0px 25px;
}

.FooterLink:hover {
  color: #9EFF2E;
}

.FooterLink:hover::before {
  content: "👉 ";
}

@media (max-width: 600px) {
  .FooterNavSection {
    display: flex;
    flex-direction: column;
  }
  .FooterLink {
    color: white;
    font-size: 1em;
    margin: 5px 0px;
    text-align: left;
  }
  .FooterTitle {
    font-weight: 700;
    font-size: 1em;
  }
}

.IconHolder {
  width: 50px;
  height: 75px;
  margin: 25px;
}

.button__front {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--orange);
  width: calc(100% + 4px);
  height: 100%;
  border: solid var(--lightOrange) 2px;
  bottom: 3px;
}

.button__background {
  position: relative;
  width: 5em;
  height: 2.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--darkOrange);
  border: solid var(--darkestOrange) 2px;
}

.greenDark {
  background-color: var(--forest);
  border: solid var(--darkGreen) 2px;
  width: 8em;
}

.green {
  background-color: var(--kelly);
  border: solid var(--lime) 2px;
}

@media (max-width: 600px) {
  .button__background {
    width: 4em;
  }

  .greenDark {
    width: 8em;
    margin: 10px 0px;
  }
  .homeBttn {
    margin: 0px;
  }
}

.customBttn:hover {
  border: solid var(--lightOrange) 2px;
}

.customBttn__green:hover {
  border: solid var(--lime) 2px;
}

.AppDark {
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: var(--darkGreen);
}

.Nav {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.fullStory__button {
  padding: 5px 10px;
  border: none;
  background-color: var(--cream);
  border-radius: 5px;
  color: var(--orange);
  width: 150px;
}

.pfpTitle {
  font-size: 1.25em;
  color: var(--darkGreen);
  font-weight: 400;
  width: 300px;
}

.quickBio {
  width: 300px;
  display: flex;
  flex-direction: column;
}

.left {
  text-align: right;
}

.pfpText {
  font-size: 1.25em;
  font-weight: 400;
  width: 300px;
  color: var(--cream);
}

.pfp {
  border-radius: 50px;
  /* box-shadow: 0px 0px 12px var(--darkGreen); */
  margin: 25px;
  width: 200px;
  height: 200px;
  background-image: url("./Assets/profilepic.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.Navbar {
  font-size: 1em;
  display: grid;
  grid-template-columns: 45% 10% 35% 10%;
  justify-items: center;
  align-items: center;
  margin: 20px;
  width: 100%;
  height: 100px;
  border-radius: 15px;
  background-color: var(--kelly);
}

@media (max-width: 600px) {
  .Nav {
    width: 100%;
  }
  .Navbar {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
}

.dropdownMenuDesign {
  right: 0;
  top: 40px;
  border-radius: 15px;
  background-color: var(--orange);
  border: solid var(--lightOrange) 3px;
  position: absolute;
  box-shadow: 3px 3px 9px rgba(25, 25, 25, .25);
}

.dropdownMenuDesign__item {
  margin: 15px;
  color: var(--creamy);
}

.navbar__buttons {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .navbar__buttons {
    font-size: 14px;
  }
}

.Navbar > div {
  display: flex;
}

.splash__page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 100%;
  position: relative;
  font-size: 1em;
}

.Switch__container {
  padding-right: 25px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  font-size: 1em;
  height: 10%;
  flex-shrink: 0;
}

@media (max-width: 600px) {
  

  .Switch__container {
    justify-content: space-around;
    right: 0px;
  }
}

.socials__icon {
  display: flex;
  justify-content: center;
  fill: black;
}

.socials__icon--dark {
  fill: var(--cream);
}

.flower__container {
  width: 15em;
  position: relative;
  z-index: 3;
}

.NoahLogo {
  width: 10em;
  position: relative;
  z-index: 3;
}

.st10 {
  fill: var(--lime)
}

.st15 {
  fill: var(--creamy)
}

.NoahLogo__smaller {
  width: 8em;
  margin: 0px;
  height: 5em;
}

.splash__page--buttons {
  font-size: .9em;
  display: flex;
  flex-direction: column;
  margin: 25px 0px 0px 0px;
  position: relative;
  z-index: 3;
}

@media  (max-width: 600px) {

  .NoahLogo {
    width: 10em;
    margin: 25px 0px 25px;
  }
  .NoahLogo__smaller {
    height: 2em;
    width: 5em;
  }
}

.dropdown__button {
  position: relative;
  margin: 20px;
}

h1 {
  color: var(--darkGreen);
}


ul {
  display: flex;
}

li {
  list-style: none;
  position: relative;
  font-size: 1em;
  font-weight: 400;
  padding: 5px 5px;
}

a {
  text-decoration: none;
}

.horizontal__menu {
  padding-left: 15px; 
  padding-right: 15px; 
  color: var(--cream);
}

.horizontal__menu--button {
  color: var(--cream);
  text-decoration: none;
  width: 100%;
}

@media (max-width: 600px) {
.horizontal__switch {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 12px;
}

}

.button__list {
  margin: 10px auto;
}


.code__list {
  color: var(--cream);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
}

@media (max-width: 600px) {
  .button__list {
    margin: 5px auto;
  }
  .code__list {
    width: auto;
    margin: 7px auto;
  }
}

.general__button {
  margin: 15px 0px;
  background: none;
  padding: 5px 10px;
  border: none;
  color: white;
  font-size: 1em;
  font-weight: 400;
  border-radius: 5px;
  position: relative;
}

.NavButton__imitation {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  font-size: .9em;
  border: none;
  background: none;
  border-radius: 5px;
  color: var(--cream);
  font-weight: 400;
  z-index: 1;
  user-select: none;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-size: .9em;
  border: none;
  background: none;
  border-radius: 5px;
  color: var(--cream);
  font-weight: 400;
  z-index: 1;
  user-select: none;
}

.button__bg {
  color: var(--darkGreen);
  background-color: var(--lime);
}

.path__button {
  font-weight: 400;
  text-decoration: none;
  padding: 0px;
  color: var(--creamy);
}

.SlideOutMenu {
  border-radius: 5px;
  border: solid var(--lightOrange) 2px;
  background-color: var(--orange);
  position: absolute;
  top: 0px;
  right: 120px;
  box-shadow: 2px 2px 12px rgba(25, 25, 25, .25);
}

.SlideOutMenu--code {
  border-radius: 5px;
  border: solid var(--lightOrange) 2px;
  background-color: var(--orange);
  position: absolute;
  top: 0px;
  right: 120px;
  box-shadow: 2px 2px 12px rgba(25, 25, 25, .25);
}


@media (max-width: 600px) {
  ul {
    display: flex;
    flex-direction: column;
  }

  .SlideOutMenu {
    right: 70px;
  }
  .SlideOutMenu--code {
    right: -110px;
    width: 100px;
    top: -50px;
  }
}

img {
  width: 100%;
}

.GraphicThumbnails {
  display: flex;
  justify-content: center;
}

.span2 {
  grid-row: span 2;
}

.Carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid white 5px;
}

.NachoBox__page {
  background-color: var(--creamy);
}

.nachoNav {
  background-color: var(--nachoblue);
}

.NachoBox__page--dark {
  background-color: var(--black);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.images {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.title__image {
  background-image: url("./Assets/Graphics/graphicThumbnail-04.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  width: 75%;
  background-color: #CEF982;
  height: 500px;
}

.caption {
  font-size: 2.75em;
  font-weight: 800;
  text-align: center;
  grid-column: span 2;
  width: 70%;
  flex-wrap: wrap;
  margin: 100px auto;
  border-radius: 5px;
  color: black;
}

@media (max-width: 600px) {
  .caption {
    width: 85%;
    font-size: 1.25em;
    margin: 50px auto;
  }
}

.process__images {
  display: flex;
  width: 70%;
  justify-content: center;
}

.process__image {
  width: 50%;
  height: 500px;
  background-position: center;
  background-size: cover;
}
.process__image--left {
  background-image: url("./Assets/NachoBoxPics/IMG_0095.jpg");
}

.process__image--right {
  background-image: url("./Assets/NachoBoxPics/Letters.jpg");
}

.sketch {
  width: 25%;
  height: 300px;
  background-size: cover;
  background-position: center;
  margin: 100px 0px;
}

.sketch--one {
  background-image: url("./Assets/NachoBoxPics/IMG_0116.jpg");
}
.sketch--two {
  background-image: url("./Assets/NachoBoxPics/IMG_0118.jpg");
}

.sketch--three {
  background-image: url("./Assets/NachoBoxPics/IMG_0114\ \(1\).jpg");
}

.sketch--four {
  background-image: url("./Assets/NachoBoxPics/c006.jpg");
}

.hero__image {
  width: 100%;
  margin: 50px 0px 0px 0px;
}

.hero__image--fonts {
  width: 70%;
}

.white {
  background-color: white;
}

.process__images--package {
  width: 100%;
}

.process__image--package {
  width: 50%;
  height: 800px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.package__left {
  background-image: url("./Assets/NachoBoxPics/packageleft-10-10.svg");
  background-color: #F78263;
}

.package__right {
  background-image: url("./Assets/NachoBoxPics/packageright-11-11.svg");
  background-color: #CEF982;
}

.HowToPage {
  background-color: var(--creamy);
  height: 100%;
}

.process__images--howto {
  width: 100%;
  display: flex;
}

.process__images--six {
  width: 20%;
  height: 300px;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 600px) {
  .title__image {
    width: 100%;
    height: 200px;
  }

  .process__images {
    width: 100%;
  }

  .process__image {
    height: 200px;
  }

  .process__images--six {
    height: 75px;
  }

  .sketch {
    height: 100px;
    margin: 0px 0px 50px 0px;
  }

  .process__image--package {
    height: 300px;
  }
}

.iconRam {
  background-image: url("./Assets/HowToPC/HowToIcons-03.svg");
  /* background-color: #DB69F7; */
  background-size: 80%;
}

.iconCooler {
  background-image: url("./Assets/HowToPC/HowToIcons-02.svg");
  /* background-color: #FFDE17; */
}

.iconMobo {
  background-image: url("./Assets/HowToPC/HowToIcons-01.svg");
  /* background-color: #31C9C5; */
}

.iconGpu {
  background-image: url("./Assets/Graphics/graphicThumbnail-01.svg");
  background-size: 80%;
}

.iconCpu {
  background-image: url("./Assets/HowToPC/HowToIcons-04.svg");
  /* background-color: #5CF779; */
  background-size: 40%;
}