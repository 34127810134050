.contactTitle {
  color: var(--creamy);
  font-size: 1.5em;
}

form {
  display: flex;
  width: 25%;
  flex-direction: column;
}

.navform {
  width: 100%;
}

@media (max-width: 600px) {
  form {
    width: 70%;
  }
}

.ContactDropdown__nav {
  position: absolute;
  right: 0px;
  top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--orange);
  color: var(--creamy);
  padding: 25px;
  border-radius: 15px;
  border: solid var(--lightOrange) 3px;
}

.ContactDropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  background-color: var(--orange);
}

@media (max-width: 600px) {
  .ContactDropdown {
    bottom: 100px;
    left: -75px;
  }
}

.contactButton {
  position: relative;
}

.contact__label {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
}

.contact__inputField {
  border: solid var(--darkOrange) 3px;
  background-color: var(--creamy);
  border-radius: 5px;
  padding: 5px;
}

.longField {
  height: 75px;
  margin-bottom: 10px;
}