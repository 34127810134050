.HomelyPage {
  /* background-color: white; */
  background-color: #dcfbf9;
  height: 100%;
}

.FirstImage {
  margin-top: 20px;
}
.homely {
  background-color: var(--blue);
}

.HomelyProcess {
  display: flex;
  width: 100%;
  margin: 50px 0px 100px 0px;
}

.homely__95 {
  width: 75%;
  margin: 25px 0px;
}

.HomelyImage {
  width: 90%;
  margin: 20px 0px;
}

.HomelyImageSmaller {
  width: 40%;
  margin: 25px;
}

.ProcessImages {
  width: 70%;
}

.ProcessImages--full {
  margin-bottom: 100px;
}

.TheJourney {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.JourneyTitle {
  /* color: var(--blue); */
  background: linear-gradient(to right, #05CEC3 0%, #02D196 100%);
  background-clip: text;
  font-weight: 900;
  width: 70%;
  text-align: right;
  font-size: 3em;
  color: transparent;
}

.JourneyTitle2 {
  background: linear-gradient(to right, #05CEC3 0%, #02D196 100%);
  background-clip: text;
  font-weight: 900;
  text-align: center;
  font-size: 3em;
  color: transparent;
  width: 60%;
  margin: 0px auto 100px auto;
}

.JourneyText {
  font-size: 1.75em;
  color: black;
  text-align: right;
  width: 70%;
  margin: 25px 0px;
  font-weight: 600;
}

@media (max-width: 600px) {

  .JourneyTitle {
    font-size: 2em;
    width: 80%;
    text-align: center;
  }

  .JourneyTitle2 {
    font-size: 1.5em;
    width: 80%;
  }

  .HomelyImageSmaller {
    width: 90%;
    margin: 0;
  }

  .ProcessImages {
    width: 100%;
  }

  .TheJourney {
    width: 100%;
  }

  .HomelyProcess {
    display: flex;
    flex-direction: column;
  }

  .JourneyText {
    font-size: 1em;
    width: 100%;
    text-align: center;
    padding: 0px 25px;
  }
}

