.CrpoPage {
  background-color: var(--creamy);
}

.hero__image--crpo {
  width: 70%;
  margin-top: 25px;
}

.crpo__caption {
  font-weight: 700;
  width: 100%;
  margin: 25px 0px;
}

.hero__image--50 {
  width: 60%;
  margin: 50px;
}

.crpo__preview {
  display: flex;
  justify-content: center;
  padding: 25px;
}

.caption__inline {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 600px) {
  .crpo__preview {
    flex-direction: column;
    width: 100%;
  }

  .caption__inline {
    margin: 0px 0px;
    width: 100%;
    font-size: .65em;
    padding: 0px;
  }

  .hero__image--50 {
    width: 100%;
    margin: 0px 0px 25px 0px;
  }

  .hero__image--crpo {
    width: 100%;
  }

  .crpo__caption {
    font-size: 1.75em;
    width: 80%;
  }
}