.storyCarousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.NoahHeadShot {
  position: relative;
  flex-shrink: 0;
  border-radius: 100px;
  width: 200px;
  height: 200px;
  background-image: url("../Assets/profilepic.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: solid var(--lime) 5px;
}

.story {
  width: 70%;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: left;
  position: relative;
  border-radius: 25px;
  margin: 50px 0px;
}

@media (min-width: 600px) {
.story__button--container {
  position: absolute;
  bottom: 50px;
  }
}

.story__title {
  color: white;
  font-size: 3em;
}

.story__text {
  color: white;
  font-size: 1.25em;
  text-align: center;
}

.skills {
  width: 35px;
  margin: 0px 10px;
}

.skills__container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.splash__page--me {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

/* .orbit__container {
  position: absolute;
  width: 500px;
  height: 500px;
  border: solid white 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.orbit {
  position: absolute;
  transform-origin: cen;
  width: 60px;
  height: 60px;
  border: solid white 6px;
  animation: orbit 5s linear infinite;
}

@keyframes orbit {
  to {
    transform: rotate(1turn);
  }
} */



.orbit__container {
  position: absolute;
  top: -108%;
  right: -108%;
  width: 600px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: orbit 20s linear infinite;
}

@media (max-width: 600px) {
  .orbit__container {
    top: -175px;
    opacity: .5;
  }
}

.logoIcon {
  width: 2em;
  height: 2em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: antiOrbit 20s linear infinite;
}

.orbit__offset {
  position: absolute;
  transform-origin: center;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orbit__offset--java {
  transform: translate(-125px, 0px);
}
.orbit__offset--css {
  transform: translate(125px, 0px);
}
.orbit__offset--html {
  transform: translate(0px, 125px);
}
.orbit__offset--react {
  transform: translate(0px, -125px);
}
.orbit__offset--illu {
  transform: translate(90px, -90px);
}
.orbit__offset--photoshop {
  transform: translate(-90px, 90px);
}
.orbit__offset--indesign {
  transform: translate(-90px, -90px);
}
.orbit__offset--xd {
  transform: translate(90px, 90px);
}


@keyframes  orbit {
  to {
    transform: rotate(1turn);
  }
}

@keyframes antiOrbit {
  to {
    transform: rotate(-1turn);
  }
}

@media (max-width: 600px) {

  .orbit__container {
    top: -200px;
    left: -300px;
  }

  .storyCarousel {
    padding: 10px;
    width: 95%;
    height: 40%;
    flex-direction: column;
    align-items: center;
    background-color: var(--orange);
    border: solid var(--lightOrange) 3px;
    border-radius: 25px;
  }
  .story {
    max-width: 90%;
    margin: 5px;
    padding: 0px;
    display: flex;
    /* flex-direction: row; */
    color: white;
    text-align: left;
    border-radius: 25px;
  }
  .story__title {
    color: var(--creamy);
    font-size: 1.5em;
  }
  
  .story__text {
    color: var(--creamy);
    font-size: .9em;
    text-align: center;
    max-width: 95%;
  }
  .splash__page--me {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .NoahHeadShot {
    width: 0px;
    height: 0px;
    top: 0;
    border: none;
  }
  .skills {
    width: 15px;
    margin: 0px 10px;
  }
  .skills__container {
    display: flex;
    justify-content: center;
    margin: 0px;
  }
}