.DarkMatterPage {
  background-color: var(--creamy);
}

.DarkMatterNav {
  background-color: #6F4394;
}

.mocks {
  margin: 50px 0px;
  width: 100%;
}

.screens__DM {
  width: 80%;
}