.titleCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleCard__one {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.projectTitle {
  font-size: 3em;
  font-weight: 600;
  text-align: right;
  width: 30%;
  margin-right: 10px;
  position: relative;
  bottom: 15px;
}

.projectText {
  text-align: left;
  width: 30%;
  margin-left: 10px;
}

@media (min-width: 1500px) {
  .projectText__padded {
    padding-right: 200px;
  }

}


.subTitle {
  font-size: .35em;
  position: relative;
}

.roleCard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.role {
  color: var(--kelly);
  font-size: .45em;
  width: 100%;
  text-align: right;
  font-weight: 700;
  margin-right: 10px;
}

.role__text {
  width: 30%;
  text-align: left;
  margin-left: 10px;
}

@media (max-width: 600px) {
  .projectText__padded {
    padding-right: 0px;
  }

  .roleCard {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .role {
    font-size: .35em;
    font-weight: 700;
    text-align: center;
    margin: 10px 0px 5px 0px;
  }

  .role__text {
    font-size: 1em;
    width: 85%;
    text-align: center;
  }
  .titleCard__one {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .projectTitle {
    bottom: 0px;
    width: 80%;
    margin: 0px;
    text-align: center;
    font-size: 2.25em;
  }

  .projectText {
    width: 85%;
    text-align: center;
    margin: 5px 0px 0px 0px;
  }
}