* {
  padding: 0px;
  margin: 0px;
  font-family: basic-sans,sans-serif;
  font-weight: 400;
  font-style: normal;
  box-sizing: border-box;
}

:root {
  --black: #272828;
  --orange: #f95b1c;
  --orangey: #ee8114;
  --lightOrange: #ffa60d;
  --darkOrange: #bb3a07;
  --darkestOrange: #7f2602;
  --darkGreen: #363f33;
  --lime: #B2D235;
  --forest: #2A6649;
  --cream: #F9F2BD;
  --creamy: #f9f5d5;
  --olive: #5A563A;
  --rose: #ff4567;
  --kelly: #008755;
  --brightKelly: #098825;
  --darkKelly: #108C21;
  --nachoblue: #23E2CB;
  --blue: #1F79FC;
  --position: 360deg;
  --start: 0deg;
}
