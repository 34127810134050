.Switch {
  position: relative;
  display: inline-block;
  width: 4em;
  height: 2em;
}

.Switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


.Switch__slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: .6em;
  background-color: var(--olive);
  transition: 250ms ease-in-out;
  /* box-shadow: inset 0px 0px 3px var(--black); */
}

.Switch__slider:before {
  position: absolute;
  border-radius: .5em;
  top: .15em;
  left: .15em;
  content: '';
  width: 2em;
  height: 1.7em;
  background-color: white;
  transition: 250ms ease-in-out;
  background-image: url('../Assets/Light-mode-icon-11.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1em;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .45);
}

input:checked + .Switch__slider:before {
  transform: translateX(1.7em);
  content: '';
  background-image: url('../Assets/Dark-mode-icon-12.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75em;
  background-color: white;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, .45);
}

input:checked + .Switch__slider {
  background-color: var(--lime);
}

.theme__icon {
  position: relative;
  z-index: 5;
  width: 50px;
}

.st17 {
  fill: var(or);
}