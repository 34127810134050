.NoahAnimation__container {
  position: absolute;
  width: 100%;
  /* transform: translateX(-50%); */
  pointer-events: none;
}



/* .letter {
  animation: shift 1.5s forwards;
  fill: var(--orange);
  stroke: var(--orange);
  stroke-width: 10px;
}

@keyframes shift {
  80% {
    transform: translate(44.5%, 40%) scale(.25);
    stroke: var(--orange);
  }
  90% {
    transform: translate(44.5%, 40%) scale(.25);
    stroke: var(--orange);
  }
  100% {
    transform: translate(45.5%, 40%) scale(.25) skew(-15deg);
    stroke: var(--orange);
  }
}

.letterBG {
  fill: var(--olive);
  stroke: var(--olive);
  stroke-width: 10px;
  animation: slide 1.5s ease-in-out forwards;
  transition: skew;
}

@keyframes slide {
  80% {
    transform: translate(44.5%, 40%) scale(.25);
  }
  90% {
    transform: translate(44.5%, 40%) scale(.25);
    fill: var(--olive);
    stroke: var(--olive)
  }
  100% {
    transform: translate(45.25%, 40.5%) scale(.25) skew(-15deg);
    fill: var(--olive);
    stroke: var(--olive)
  }
}; */



